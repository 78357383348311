import React from 'react'
import Layout from '../components/layout'
import Design from '../components/categories/design'
import Seo from '../components/seo'

const DesignPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Design />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Design Freelancers | Codestaff'
  const description =
    'Hire the best Design freelancers at Codestaff. Get the top 1% of Design professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default DesignPage
